import React, { useState } from 'react';
import SearchBar from './SearchBar'; 
import Login from './Login';

function App() {
  const [searchResult, setSearchResult] = useState("");  // Simplified for direct use as string
  const [authenticated, setAuthenticated] = useState(false);

  const handleSearchResults = result => {
    console.log('Search result:', result); // Log result to debug
    setSearchResult(result); // Assuming result is a string
  };

  const handleLoginSuccess = (accessToken) => {
    // Save accessToken to localStorage or session storage
    setAuthenticated(true);
  };

  const handleLogout = () => {
    // Clear accessToken from storage
    setAuthenticated(false);
  };

  return (
    <div>
      <h1 style={{textAlign: 'center', color: 'darkblue'}}>Pairing Data + Human Connection</h1>
      {authenticated ? (
        <>
          <SearchBar onSearch={handleSearchResults} />
          {/* Display the result */}
          <div>
            <p style={{marginLeft: '20px', marginRight: '20px'}}>Reasoning: {searchResult}</p>
          </div>
          <button onClick={handleLogout} style={{position: 'absolute', top: '10px', right: '10px', backgroundColor: 'darkblue', color: 'white', marginBottom: '10px' }}>Logout</button>
          {/* Render authenticated content */}
          
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;





import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; 

const Login = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const user = {
                username: username,
                password: password
            };

            const {data} = await axios.post(' https://genai-demo-6onvxnvwqa-ue.a.run.app/token/', user ,{headers: {
                'Content-Type': 'application/json'
            }}, {withCredentials: true});
            
            if (data.access) {
                localStorage.clear();
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;
                onLoginSuccess(data.access);
            } else {
                setError('Invalid login credentials');
            }
        } catch (err) {
            setError('Error logging in. Please try again.');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin}>
                <h2>Login</h2>
                {error && <p className="error">{error}</p>}
                <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" style={{ backgroundColor: 'darkblue', color: 'white' }}>Login</button>
            </form>
        </div>
    );
};

export default Login;



